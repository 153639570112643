import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { observer } from 'mobx-react-lite'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { ELearningCoursePlanItem } from '../../model/ELearningCoursePlanItem'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import UpdateIcon from '@mui/icons-material/Update'
import { Tooltip, Box, Card, CardMedia, CardContent, Typography, Rating } from '@mui/material'
import { CourseProgressRow } from './CourseProgressRow'
import { useState } from 'react'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { Paragraph } from 'src/feature/localization/component/atom/Paragraph'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useELearningStore } from '../../store/e-learning.store'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'

export type CoursePlanItemProp = {
  item: ELearningCoursePlanItem
  index: number
  managerView?: boolean
}

export const CoursePlanItem = observer(({ item, managerView }: CoursePlanItemProp) => {
  const { translation } = useLocalizationStore()
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()
  const texts = translation.pages.eLearning
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const removeItem = async () => {
    await eLearningStore.removeCoursePlanItem(item.id)
    setShowConfirmDelete(false)
  }

  return (
    <Card sx={styles.card}>
      <Box sx={styles.mediaContainer} onClick={() => navigate(appRoutePath.learningCourse(item.courseId))}>
        <CardMedia
          component="img"
          alt={item.title}
          image={item.imageUrl}
          sx={styles.image}
        />
        <Box sx={styles.overlay} />
      </Box>
      <CardContent sx={styles.contentContainer}>
        <Title3 sx={styles.text} textValue={item.title} noWrap />
        <AppBox sx={{ gap: 5, alignItems: 'center' }}>
          <Typography variant='body2' sx={{ fontSize: '0.7rem', color: 'text.alternate' }}>{`${'0,0'} (${0} ratings)`}</Typography>
          {!managerView && (item?.progress?.percentage || 0) > 50 && <Rating defaultValue={0} size="small" sx={styles.icon} />}
        </AppBox>
        <AppBox sx={styles.row}>
          <CourseProgressRow progress={item.progress} />
          {!managerView && item.isUserSelected && <AppIconButton sx={styles.iconButton} onClick={() => setShowConfirmDelete(true)}><DeleteIcon fontSize='medium' /></AppIconButton>}
          {item.mandatory && <Tooltip title={(item.dealine ? new Date(item.dealine) < new Date() ? texts.courseOverdue(new Date(item.dealine).toLocaleDateString()) : texts.courseMandatoryWithDeadline(new Date(item.dealine).toLocaleDateString()) : texts.courseMandatory())}><UpdateIcon fontSize='medium' style={{ color: Palette.DANGER }} /></Tooltip>}
        </AppBox>
      </CardContent>
      <ModalWindow
        modalProps={{ open: showConfirmDelete, onClose: () => setShowConfirmDelete(false) }}
        titleProps={{ textValue: 'Remove course?' }}
      >
        <AppBox sx={styles.dialogContent}>
          <Paragraph textValue={texts.confirmDelete(item.title)} m={5} />
          <AppDivider sx={{ my: 7 }} />
          <AppBox sx={{ gap: 10, justifyContent: 'flex-end' }}>
            <AppButton
              textValue={'Cancel'}
              variant={'contained'}
              onClick={() => setShowConfirmDelete(false)}
            />
            <AppButton
              textValue={translation.buttons.confirm}
              variant={'contained'}
              onClick={removeItem}
            />
          </AppBox>
        </AppBox>
      </ModalWindow>
    </Card>
  )
})

const styles = {
  card: {
    maxWidth: 260,
    minWidth: 260,
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  mediaContainer: {
    position: 'relative',
    width: '100%',
    aspectRatio: '16 / 9', // 16:9 aspect ratio
    overflow: 'hidden',
    borderRadius: '10px 10px 0 0',
    transition: 'transform 0.5s ease',
    '&:hover img': {
      transform: 'scale(1.15)',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.5s ease',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: Palette.DEEP_SEA_BLUE,
    opacity: 0.5, // 50% black overlay
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },
  row: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    fontSize: '0.7rem',
    color: 'text.primary',
  },
  iconButton: {
    borderWidth: 0,
    padding: 0,
    color: 'text.primary',
    '&:hover': {
      color: Palette.DANGER,
    },
  },
  icon: {
    color: '#EECB11',
    '&:hover': {
      opacity: 0.7,
    },
  },
  dialogContent: {
    flexDirection: 'column',
  },
}
