import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, circularProgressClasses, Collapse, Fade, Typography } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useDashboardStore } from '../store/dashboard.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'

export type UserSkillGapAnalysisProps = {
  userId: string
}

export const UserSkillGapAnalysis = observer(({ userId }: UserSkillGapAnalysisProps) => {
  const skillAssessmentStore = useSkillAssessmentStore()
  const dashboardStore = useDashboardStore()
  const [open, setOpen] = useState<boolean>(false)
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')

  useEffect(() => {
    skillAssessmentStore.skillSets = undefined
    skillAssessmentStore.getSkillSets(userId)
  }, [])

  useEffect(() => {
    if (!isLoading && skillAssessmentStore.skillSets && skillAssessmentStore.skillSets?.skills?.length !== 0) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isLoading, skillAssessmentStore.skillSets?.skills?.length])

  return (
    <>
      <Collapse in={!open} timeout={400} mountOnEnter unmountOnExit>
        <Typography sx={{ fontWeight: 600, color: Palette.DANGER, textAlign: 'center' }}>
          {dashboardStore.userOverview?.[userId]?.userName} hasn't rated any skills yet. Please reach out to her.
        </Typography>
      </Collapse>
      <Collapse in={open} timeout={400} mountOnEnter unmountOnExit>
        <Box sx={styles.root}>
          <Title1>{dashboardStore.userOverview?.[userId]?.userName}'s Skill Gap Analysis</Title1>
          <AppBox sx={styles.ratingWrapper}>
            {skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
              <Box sx={{ position: 'relative', display: 'inline-flex', width: '205px', height: '205px' }} key={skill.id}>
                {/* Expected level ring */}
                <Fade in={open} timeout={600} mountOnEnter unmountOnExit>
                  <CircularProgress variant="determinate" value={100} sx={{
                    ...styles.circleExpectedPosition,
                    position: 'absolute',
                    color: Palette.BRIGHT_GREY_2,
                  }} size={205} thickness={2} />
                </Fade>
                <Fade in={open} timeout={500} mountOnEnter unmountOnExit>
                  <CircularProgress variant="determinate" sx={{
                    ...styles.circleExpectedPosition,
                    color: '#36bbaf69',
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }} size={205} thickness={2} value={((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100} />
                </Fade>
                {/* Selected level ring */}
                <Fade in={open} timeout={600} mountOnEnter unmountOnExit>
                  <CircularProgress variant="determinate" value={100} sx={{
                    ...styles.circleSelectedPosition,
                    position: 'absolute',
                    color: Palette.BRIGHT_GREY_2,
                  }} size={180} thickness={4} />
                </Fade>
                <Fade in={open} timeout={800} mountOnEnter unmountOnExit>
                  <CircularProgress variant="determinate" sx={{
                    ...styles.circleSelectedPosition,
                    color: Palette.HAN_BLUE,
                    position: 'absolute',
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: 'round',
                    },
                  }} size={180} thickness={4} value={((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100} />
                </Fade>
                <Fade in={open} timeout={600} mountOnEnter unmountOnExit>
                  <Box
                    sx={{
                      ...styles.circleExpectedPosition,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Caption
                      variant="caption"
                      component="div"
                      color="text.primary"
                      sx={{ fontWeight: '500' }}
                    >
                      <AppBox sx={styles.circleContainer}>
                        <Typography sx={styles.wordWrapper}>{skill.setName}</Typography>
                        <Fade in={open} timeout={600} mountOnEnter unmountOnExit>
                          <Typography sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>{`${((skillAssessmentStore.skillSets?.skills?.[index]?.selectedSetLevel || 0) / 5) * 100}% | ${((skillAssessmentStore.skillSets?.skills?.[index]?.expected_set_level || 0) / 5) * 100}%`}</Typography>
                        </Fade>
                      </AppBox>
                    </Caption>
                  </Box>
                </Fade>
              </Box>
            ))}
          </AppBox>
        </Box>
      </Collapse>
    </>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: '2rem',
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '3rem',
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
  },
  circleExpectedPosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  circleSelectedPosition: {
    top: 12,
    left: 12,
    bottom: 12,
    right: 12,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
  },
}