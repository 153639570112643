import { Box, Fade, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useState } from 'react'
import { useControlPanelStore } from 'src/feature/control-panel/store/control-panel.store'
import { AddUser } from './AddUser'

export type UserMetricsProps = {
  levelId: string
}

export const UserMetrics = observer(({ levelId }: UserMetricsProps) => {
  const dashboardStore = useDashboardStore()
  const controlPanelStore = useControlPanelStore()
  const [showInviteDialog, setShowInvite] = useState(false)

  const closeInvite = (reload: boolean) => {
    if (reload) {
      controlPanelStore.updateOrganisationUsers()
    }

    setShowInvite(false)
  }

  return (
    <Box sx={styles.root}>
      <Title1>{dashboardStore.teamOverview?.[levelId]?.organisationLevelName}</Title1>
      <Typography variant={'body1'} sx={{ width: '70%', textAlign: 'center' }}>{'As a team manager you are free to see all our teams progress. Make sure to visit all team and people to follow the progress and assign  new courses to your teams with eae'}</Typography>
      <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='large' label={'Total Users'} value={dashboardStore.teamOverview?.[levelId]?.totalUsers || 0} />
          <MetricWithValue size='large' label={'Active Users'} value={dashboardStore.teamOverview?.[levelId]?.activeUsers || 0} />
          <MetricWithValue size='large' label={'Inactive Users'} value={dashboardStore.teamOverview?.[levelId]?.inactiveUsers || 0} />
          <MetricWithValue size='large' label={'Courses Given'} value={dashboardStore.teamOverview?.[levelId]?.assignedCourses || 0} />
          <MetricWithValue size='large' label={'Users Complete'} value={dashboardStore.teamOverview?.[levelId]?.totalCoursesUsersCompleted || 0} />
          <MetricWithValue size='large'
            color={(dashboardStore.teamOverview?.[levelId]?.completionRate || 0) < 50 ? 'error.main' : 'success.main'}
            label={'Complete Rate'}
            value={`${dashboardStore.teamOverview?.[levelId]?.completionRate || 0}%`}
          />
        </AppBox>
      </Fade>
      <AppBox sx={styles.buttonWrapper}>
        <AppButton variant='contained' color="primary" onClick={() => setShowInvite(true)}>{'Add user'}</AppButton>
        <AppButton variant='contained' color="primary">{'Download report'}</AppButton>
      </AppBox>
      {showInviteDialog && <AddUser levelId={levelId} show={showInviteDialog} onClose={closeInvite} />}
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(6, 1fr)' },
    columnGap: '5rem',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: 10,
  },
}