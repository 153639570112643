import { observer } from 'mobx-react-lite'
import { Card, CardContent, CardMedia, Box, Typography } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { Title3 } from 'src/feature/localization/component/atom/Title3'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { AppIconButton } from 'src/component/common/analog/molecule/button/AppIconButton'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { CourseListItem } from '../../model/ELearningCourseListItem'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Palette } from 'src/feature/theme/model/Palette'
import { useEffect, useState } from 'react'
import { useAuthStore } from 'src/feature/auth/store/auth.store'
import { OrganisationRole } from 'src/feature/control-panel/model/OrganisationRole'
import SettingsIcon from '@mui/icons-material/Settings'
import { OrgSettingsModal } from './OrgSettingsModal'

export type CourseListPresentationItemProps = {
  item: CourseListItem
  courseSelectedFlag?: boolean
  courseSelectedId?: (courseId: string) => void
}

export const CourseListPresentationItem = observer(({ item, courseSelectedFlag = false, courseSelectedId }: CourseListPresentationItemProps) => {
  const eLearningStore = useELearningStore()
  const navigate = useNavigate()
  const authStore = useAuthStore()
  const [isFavorite, setIsFavorite] = useState(item.isFavorite)
  const [showOrgSettings, setShowOrgSettings] = useState(false)

  const toggleFavorite = async (e: React.FormEvent<HTMLFormElement>, id: string) => {
    e.stopPropagation()
    setIsFavorite(prev => !prev)
    await eLearningStore.toggleFavoriteCourse(id)
    await eLearningStore.updatePersonalCoursePlan()
  }

  useEffect(() => {
    eLearningStore.getCourseInfo(item.id)
  }, [eLearningStore, item.id])

  return (
    <>
      <Card sx={styles.card} key={item.id}>
        <Box sx={styles.mediaContainer} onClick={() => courseSelectedId ? courseSelectedFlag && courseSelectedId(item.id) : navigate(appRoutePath.learningCourse(item.id))}>
          <CardMedia
            component="img"
            alt="course icon/image"
            image={item.imageUrl}
            sx={styles.image}
          />
          <Box sx={styles.overlay} />
        </Box>
        <CardContent sx={styles.contentContainer}>
          <AppBox sx={styles.actions}>
            <Title3 sx={{ ...styles.text, color: 'text.primary', '-webkit-line-clamp': '2' }} textValue={item.title} />
            <AppIconButton
              onClick={(e: React.FormEvent<HTMLFormElement>) => toggleFavorite(e, item.id)}
              sx={styles.iconButton}
            >
              {isFavorite ? <FavoriteIcon fontSize='medium' /> : <FavoriteBorderIcon fontSize='medium' />}
            </AppIconButton>
          </AppBox>
          <AppBox sx={styles.actions}>
            <AppBox sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography sx={{ ...styles.text, color: 'text.alternate', '-webkit-line-clamp': '1' }}>{item.category || 'NA'}</Typography>
              <Typography variant='body2' sx={{ fontSize: '0.7rem', color: 'text.alternate' }}>{`${'0,0'} (${0} ratings)`}</Typography>
            </AppBox>
            {authStore.hasRole(OrganisationRole.LearningAdministrator) && <AppIconButton onClick={() => setShowOrgSettings(true)} sx={styles.iconButton}><SettingsIcon fontSize='medium' /></AppIconButton>}
          </AppBox>
        </CardContent>
      </Card>
      {authStore.hasRole(OrganisationRole.LearningAdministrator) && showOrgSettings && <OrgSettingsModal show={showOrgSettings} courseId={item.id} onClose={() => setShowOrgSettings(false)} />}
    </>
  )
})

const styles = {
  card: {
    maxWidth: 260,
    minWidth: 260,
    cursor: 'pointer',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  mediaContainer: {
    position: 'relative',
    width: '100%',
    aspectRatio: '16 / 9', // 16:9 aspect ratio
    overflow: 'hidden',
    borderRadius: '10px 10px 0 0',
    transition: 'transform 0.5s ease',
    '&:hover img': {
      transform: 'scale(1.15)',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.5s ease',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: Palette.DEEP_SEA_BLUE,
    opacity: 0.5, // 50% black overlay
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '6rem',
    gap: 5,
    paddingBottom: 0,
  },
  text: {
    fontSize: '0.7rem',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    '-webkit-box-orient': 'vertical',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: '5px',
  },
  iconButton: {
    borderWidth: 0,
    padding: 0,
    color: Palette.HAN_BLUE,
    '&:hover': {
      color: Palette.HAN_BLUE_LIGHT,
    },
  },
}
