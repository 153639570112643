import { AppBox } from '../../../../component/common/analog/molecule/AppBox'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Avatar, Box, Chip, Fade, Typography, useMediaQuery } from '@mui/material'
import { useELearningStore } from '../../store/e-learning.store'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { CourseListPresentationItem } from './CourseListPresentationItem'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'
import { CourseItem } from './CourseItem'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { CourseSearch } from './CourseSearch'
import { Palette } from 'src/feature/theme/model/Palette'

export type CourseListProps = {
  categoryKey: string
  categoryTitle: string
}

export const CourseList = observer((props: CourseListProps) => {
  const eLearningStore = useELearningStore()
  const { translation, locale } = useLocalizationStore()
  const [searchTerm, setSearchTerm] = useState('')
  const texts = translation.pages.eLearning
  const { isLoading: isLoadingCoursesByCategory } = eLearningStore.getGenericFieldsState('getCoursesByCategory')
  const { isLoading: isLoadingAllCourses } = eLearningStore.getGenericFieldsState('getAllCourses')
  const isLoading = isLoadingCoursesByCategory || isLoadingAllCourses
  const pageSize = 30
  const [selectedSubCategory, setSelectedSubCategory] = useState<string | ''>('')
  const isSmallScreen = useMediaQuery('(max-width:900px)')

  useEffect(() => {
    eLearningStore.loadSubCategoriesIfNeeded()
    clearValues()
    setSelectedFilterValues('all')
    eLearningStore.courseSubCategory = []
    eLearningStore.getCourseSubCategory(props.categoryKey)
    eLearningStore.getAllCourses(props.categoryKey, 0, pageSize, locale)
  }, [])

  const clearValues = () => {
    eLearningStore.courseList = []
    eLearningStore.courseCount = 0
  }

  const setSelectedFilterValues = (type: string) => {
    const categoryId = eLearningStore.categories?.find((category: ELearningCourseCategory) => category.titleLocale === props.categoryKey)?.id
    const filteredSubs = eLearningStore.categoriesWithSubs?.filter((subCategory: any) => subCategory.category.id === categoryId).map((subCategory: any) => subCategory.subCategory)
    
    if (type === 'all') {
      eLearningStore.selectedFilters = filteredSubs
    } else {
      const selectedSub = filteredSubs.find((subCategory: any) => subCategory.id === type)
      eLearningStore.selectedFilters = [selectedSub]
    }
  }

  const transform = (category: ELearningCourseCategory, index: number) => {
    return <CourseItem key={category.id} item={category} index={index} selectedSubCategory={selectedSubCategory} clickHandler={getCourses} />
  }

  const getCourses = async (subCategory: ELearningCourseCategory) => {
    clearValues()
  
    if (searchTerm.length > 0) {
      if (selectedSubCategory === subCategory.id) {
        setSelectedSubCategory('')
        setSelectedFilterValues('all')
      } else {
        setSelectedSubCategory(subCategory.id)
        setSelectedFilterValues(subCategory.id)
      }
      await eLearningStore.search(searchTerm, 0, pageSize, locale)
      
      return
    }
  
    if (selectedSubCategory === subCategory.id) {
      setSelectedSubCategory('')
      await eLearningStore.getAllCourses(props.categoryKey, 0, pageSize, locale)
    } else {
      setSelectedSubCategory(subCategory.id)
      await eLearningStore.getCoursesByCategory(subCategory.id, 0, pageSize, locale)
    }
  }

  const extendResults = async () => {
    if (eLearningStore.courseList && eLearningStore.courseList.length !== eLearningStore.courseCount) {
      if (searchTerm.length > 0) {
        await eLearningStore.appendSearch(searchTerm, eLearningStore.courseList.length, pageSize, locale)
      } else if (selectedSubCategory) {
        await eLearningStore.appendCoursesByCategory(selectedSubCategory, eLearningStore.courseList.length, pageSize, locale)
      } else {
        await eLearningStore.appendAllCourses(props.categoryKey, eLearningStore.courseList.length, pageSize, locale)
      }
    }
  }

  return (
    <AppBox sx={styles.root}>
      <AppBox sx={{ flexDirection: 'column', gap: 15 }}>
        <CourseSearch categoryKey={props.categoryKey} setSearchTerm={setSearchTerm} />
        <Box sx={styles.chipContainer}>
          <Fade in={true} timeout={50} mountOnEnter unmountOnExit>
            <Chip
              avatar={<Avatar sx={styles.avatar}>{props.categoryTitle.split(' ')
                .map(word => word[0])
                .join('')}</Avatar>}
              sx={{ justifyContent: 'start', height: '1.7rem', backgroundColor: 'primary.main' }}
              label={props.categoryTitle}
            />
          </Fade>
        </Box>
        {!isSmallScreen && <Box sx={styles.chipContainer}>
          {eLearningStore.courseSubCategory && eLearningStore.courseSubCategory?.length > 1 && eLearningStore.courseSubCategory.map(transform)}
        </Box>}
        {isSmallScreen && <AppCarousel showProgressBar={false}>
          {eLearningStore.courseSubCategory && eLearningStore.courseSubCategory?.length > 1 && eLearningStore.courseSubCategory.map(transform)}
        </AppCarousel>
        }
      </AppBox>
      <AppProgressLine isShowing={isLoading} />
      {eLearningStore.courseCount !== undefined && eLearningStore.courseCount !== 0 && <Typography variant='h4'>{eLearningStore.courseCount} courses found!</Typography>}
      {!isLoading && (eLearningStore.courseList === undefined || eLearningStore.courseList.length === 0) && <Caption textValue={texts.missingCourse} />}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && (
        <Fade in={eLearningStore.courseList?.length > 0} timeout={500} mountOnEnter unmountOnExit>
          <AppBox sx={styles.courseList}>
            {eLearningStore.courseList.map(course => (
              <CourseListPresentationItem key={course.id} item={course} />
            ))}
          </AppBox>
        </Fade>
      )}
      {!isLoading && eLearningStore.courseList && eLearningStore.courseList.length > 0 && eLearningStore.courseList.length !== eLearningStore.courseCount && (
        <AppBox sx={{ justifyContent: 'center' }}>
          <AppButton variant='contained' color="primary" onClick={extendResults}>Load more courses</AppButton>
        </AppBox>
      )}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
    padding: '0 3rem',
  },
  avatar: {
    backgroundColor: Palette.DEEP_SEA_BLUE,
    color: `${Palette.WHITE} !important`,
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
  chipContainer: {
    display: 'grid',
    gridTemplateColumns: { lg: 'repeat(7, 1fr)', md: 'repeat(5, 1fr)' },
    columnGap: 10,
    rowGap: 8,
  },
  courseList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: 10,
    rowGap: 10,
  },
}
