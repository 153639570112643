import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { Avatar, Box, Chip } from '@mui/material'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { DepartmentCards } from './DepartmentCards'
import { useState } from 'react'

export const DepartmentList = observer(() => {
  const dashboardStore = useDashboardStore()
  const [selectedSort, setSelectedSort] = useState<string>('')

  const setSelectedFilter = (label: string) => {
    if (selectedSort === label) {
      setSelectedSort('')
    } else {
      setSelectedSort(label)
      switch (label) {
      // case 'Performers':
      //   dashboardStore.teamCourseOverview?.organisationLevelIds?.sort((a, b) => dashboardStore.teamOverview[b].completionRate - dashboardStore.teamOverview[a].completionRate)
      //   break
      case 'Active':
        dashboardStore.teamCourseOverview?.organisationLevelIds?.sort((a, b) => dashboardStore.teamOverview[b].activeUsers - dashboardStore.teamOverview[a].activeUsers)
        break
      case 'Non-Active':
        dashboardStore.teamCourseOverview?.organisationLevelIds?.sort((a, b) => dashboardStore.teamOverview[a].activeUsers - dashboardStore.teamOverview[b].activeUsers)
        break
      // case 'Non-Performers':
      //   dashboardStore.teamCourseOverview?.organisationLevelIds?.sort((a, b) => dashboardStore.teamOverview[a].completionRate - dashboardStore.teamOverview[b].completionRate)
      //   break
      default:
        break
      }
    }
  }
  
  return (
    <Box sx={styles.cardWrapper}>
      <Title1>Team Development</Title1>
      <AppBox sx={{ flexDirection: 'row', gap: 10, width: '100%', alignItems: 'center' }}>
        <Title2 m={0}>Sort:</Title2>
        <Box sx={styles.chipContainer}>
          {['Active', 'Non-Active'].map((label: string) => (
            <Chip
              key={label}
              avatar={<Avatar sx={styles.avatar}>{label.split(' ')
                .map(word => word[0])
                .join('')}</Avatar>}
              sx={{ justifyContent: 'start', height: '1.7rem', ...(selectedSort !== label ? { borderColor: 'secondary.main' } : { backgroundColor: 'primary.main' }) }}
              label={label}
              onClick={() => setSelectedFilter(label)}
            />
          ))}
        </Box>
      </AppBox>
      <AppBox sx={styles.cardContainer}>
        {(dashboardStore.teamCourseOverview?.organisationLevelIds?.length ?? 0) > 0 && dashboardStore.teamCourseOverview?.organisationLevelIds?.map((levelId: string) => (
          <DepartmentCards key={levelId} levelId={levelId} />
        ))}
      </AppBox>
    </Box>
  )
})

const styles = {
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
  },
  chipContainer: {
    display: 'flex',
    columnGap: 10,
    rowGap: 8,
    width: '100%',
  },
  avatar: {
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' },
    gap: 20,
  },
}
