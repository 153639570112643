import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { Avatar, Chip, Fade } from '@mui/material'
import { startCase } from 'lodash'
import { Palette } from 'src/feature/theme/model/Palette'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
  selectedSubCategory: string
  clickHandler?: (item: ELearningCourseCategory) => void
}

export const CourseItem = observer(({ item, index, selectedSubCategory, clickHandler }: CategoryItemProp) => {
  return (
    <Fade in={true} timeout={index * 50} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        avatar={<Avatar sx={styles.avatar}>{item.titleLocale.split(' ')
          .map(word => word[0])
          .join('')}</Avatar>}
        variant='outlined'
        sx={{ justifyContent: 'start', height: '1.7rem', ...(selectedSubCategory !== item.id ? { borderColor: Palette.DEEP_SEA_BLUE } : { backgroundColor: 'primary.main' }) }}
        label={startCase(item.titleLocale)}
        onClick={() => clickHandler && clickHandler(item)}
      />
    </Fade>
  )
})

const styles = {
  avatar: {
    backgroundColor: Palette.DEEP_SEA_BLUE,
    color: `${Palette.WHITE} !important`,
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
}
