import { observer } from 'mobx-react-lite'
import { Autocomplete, Box, CircularProgress, circularProgressClasses, createFilterOptions, Fade, Typography, useTheme } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { useSkillAssessmentStore } from '../../skill-assessment/store/skill-assessment.store'
import { useEffect, useState } from 'react'
import { ProfessionType, SkillSet } from '../../skill-assessment/model/skill-assessment-model'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { reaction } from 'mobx'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { AppTextField } from 'src/feature/form/component/analog/AppTextField'
import { ExpectedLevels } from './ExpectedLevels'
import { useNotificationStore } from 'src/feature/notification/store/notification.store'

export type ExpectedRatingProps = {
  levelId: string
}

export const ExpectedRating = observer(({ levelId }: ExpectedRatingProps) => {
  const [progressValues, setProgressValues] = useState<number[]>([])
  const skillAssessmentStore = useSkillAssessmentStore()
  const notificationStore = useNotificationStore()
  const theme = useTheme()
  const { isLoading: isSkillcategory } = skillAssessmentStore.getGenericFieldsState('getSkillCategory')
  const { isLoading: isProfessionLoading } = skillAssessmentStore.getGenericFieldsState('getProfessions')
  const { isLoading: isAddSkillCategory } = skillAssessmentStore.getGenericFieldsState('addSkillCategory')
  const isLoading = isSkillcategory || isProfessionLoading || isAddSkillCategory
  const [profession, setProfession] = useState<ProfessionType | null>(null)
  const filter = createFilterOptions<ProfessionType>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [skillAssessmentSetIndex, setSkillAssessmentSetIndex] = useState<number>(0)

  useEffect(() => {
    skillAssessmentStore.professionsData = undefined
    skillAssessmentStore.skillCategory = undefined
    skillAssessmentStore.getProfessions()
    skillAssessmentStore.getSkillCategory(levelId)
    updateProgressValues()
    reaction(
      () => skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills.map(skill => skill.expected_set_level),
      updateProgressValues,
    )
  }, [skillAssessmentStore])

  const updateProgressValues = () => {
    if (skillAssessmentStore.skillCategory?.skills) {
      const initialProgressValues = skillAssessmentStore.skillCategory?.skills.map(
        skill => ((skill?.expected_set_level || 0) / 5) * 100,
      )
      setProgressValues(initialProgressValues)
    }
  }

  const handleProfessionChange = (_: any, newValue: ProfessionType | null) => {
    if (newValue && newValue.inputValue) {
      setProfession({
        id: newValue.id,
        title: newValue.inputValue,
      })
    } else {
      setProfession(newValue || null)
    }
  }

  const handleProfessionFilterChange = (options: Array<ProfessionType>, params: any) => {
    const filtered = filter(options, params)
    const { inputValue } = params
    const isExisting = options.some(option => inputValue === option.title)
    
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        inputValue,
        id: `default-id-'${inputValue.toLowerCase()}'`,
        title: `Add '${inputValue}'`,
      })
    }

    return filtered
  }

  const handleProfessionSave = async () => {
    const titleRegex = /^.{1,50}$/
    
    if (profession && titleRegex.test(profession.title)) {
      await skillAssessmentStore.addSkillCategory(profession.title, levelId)
      if (!isLoading) {
        notificationStore.enqueueInfo('Profession added successfully.')
        skillAssessmentStore.skillCategory = undefined
        skillAssessmentStore.getSkillCategory(levelId)
      }
    } else {
      notificationStore.enqueueError('Profession title must be between 1 and 50 characters.')
    }
  }

  const handleRateSkillSetClick = (selectedSkillSetIndex: number) => {
    setSkillAssessmentSetIndex(selectedSkillSetIndex)
    setIsModalOpen(true)
  }

  return (
    <AppBox sx={styles.root}>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
      {!isLoading && !skillAssessmentStore.skillCategory && <Box sx={styles.noSkillWrapper}>
        <AppBox sx={styles.wordContainer}>To start the competency analysis, you need to fill in your professional role.</AppBox>
        <AppBox sx={styles.professionSelect}>
          <Autocomplete
            style={{ width: '100%' }}
            value={profession}
            onChange={handleProfessionChange}
            filterOptions={handleProfessionFilterChange}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id='profession'
            options={skillAssessmentStore.professionsData || []}
            getOptionLabel={option => option.title || ''}
            renderOption={(props, option) => (
              <li {...props}>
                {option.title}
              </li>
            )}
            renderInput={params => (
              <AppTextField {...params} placeholder='Find your professional role...' />
            )}
          />
          <AppButton variant='contained' onClick={handleProfessionSave}>Save</AppButton>
        </AppBox>
      </Box>}
      {!isLoading && skillAssessmentStore.skillCategory && <AppBox sx={styles.ratingWrapper}>
        {!isLoading && skillAssessmentStore.skillCategory && skillAssessmentStore.skillCategory?.skills?.length !== 0 && skillAssessmentStore.skillCategory?.skills?.map((skill: SkillSet, index: number) => (
          <Fade in={true} timeout={theme.transitions.duration.standard} key={skill.id} mountOnEnter unmountOnExit>
            <Box sx={{ position: 'relative', display: 'inline-flex', width: '180px', height: '180px' }} key={skill.id}>
              <CircularProgress variant="determinate" value={100} sx={{
                ...styles.circlePosition,
                position: 'absolute',
                color: Palette.BRIGHT_GREY_2,
                transition: theme.transitions.create('all'),
              }} size={180} thickness={4} />
              <CircularProgress variant="determinate" sx={{
                color: Palette.HAN_BLUE,
                animationDuration: '600ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
                transition: theme.transitions.create('all'),
              }} size={180} thickness={4} value={progressValues[index]} />
              <Box
                sx={{
                  ...styles.circlePosition,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: theme.transitions.create('all'),
                }}
              >
                <Caption
                  variant="caption"
                  component="div"
                  sx={{ fontWeight: '500' }}
                >
                  <AppBox sx={styles.circleContainer}>
                    <Typography sx={styles.wordWrapper}>{skill.setName}</Typography>
                    <AppButton sx={styles.buttonContainer} onClick={() => handleRateSkillSetClick(index)}>{progressValues[index] === 0 ? 'Rate Skill' : 'Update Skill'}</AppButton>
                  </AppBox>
                </Caption>
              </Box>
            </Box>
          </Fade>
        ))}
      </AppBox>}
      {!isLoading && skillAssessmentStore.skillCategory?.skills && <ExpectedLevels skillAssessmentSetIndex={skillAssessmentSetIndex} isModalOpen={isModalOpen} isModalClose={() => setIsModalOpen(false)} />}
    </AppBox>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '3rem',
    backgroundColor: Palette.DEEP_SEA_BLUE,
    width: '100%',
    padding: '4rem 0',
  },
  noSkillWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  ratingWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    justifyContent: 'center',
    rowGap: '1.5rem',
    columnGap: '5.5rem',
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
    gap: 7,
  },
  circlePosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
    color: Palette.WHITE,
  },
  buttonContainer: {
    backgroundColor: Palette.BRIGHT_GREY_2,
    color: Palette.BLACK_OUT,
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: '400',
    borderColor: Palette.BRIGHT_GREY_2,
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
  wordContainer: {
    fontWeight: 600,
    fontSize: '18px',
    color: Palette.WHITE,
  },
  professionSelect: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'flex-start',
    width: '100%',
  },
}