import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { addSkillAssessment } from '../api/skill-assessment-api'
import { Palette } from 'src/feature/theme/model/Palette'
import { SkillSet, SkillSetLevel } from '../model/skill-assessment-model'
import { ModalWindow } from 'src/component/common/analog/template/ModalWindow'
import { useEffect, useState } from 'react'
import { AppConfetti } from 'src/component/common/analog/molecule/confetti/AppConfetti'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import StepButton from '@mui/material/StepButton'
import { Tooltip, Typography, useTheme, Zoom } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { AppDivider } from 'src/component/common/analog/molecule/AppDivider'

export type SkillAssessmentLevelsProps = {
  isModalClose: () => void
  isModalOpen: boolean
  skillAssessmentSetIndex: number
}

export const SkillAssessmentLevels = observer((props: SkillAssessmentLevelsProps) => {
  const skillAssessmentStore = useSkillAssessmentStore()
  const skillAssessmentSet = skillAssessmentStore.skillSets ?? { skills: [] }
  const [isAllSkillsRated, setIsAllSkillsRated] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(props.skillAssessmentSetIndex)
  const theme = useTheme()

  useEffect(() => {
    setActiveStep(props.skillAssessmentSetIndex)
  }, [props.skillAssessmentSetIndex])

  const addUserSkillAssessment = async (skillId: string, selectedSetLevel: number) => {
    await addSkillAssessment(skillId, selectedSetLevel)
    skillAssessmentSet.skills[activeStep].selectedSetLevel = selectedSetLevel
    activeStep < skillAssessmentSet.skills.length - 1 && setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBackStep = () => {
    if (activeStep > 0) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }
  }

  const handleFinish = () => {
    checkIfAllSkillsAreRated()
    props.isModalClose()
  }

  const checkIfAllSkillsAreRated = () => {
    const allSkillsRated: boolean = skillAssessmentSet?.skills?.every(skill => skill.selectedSetLevel !== null) || false
    setIsAllSkillsRated(allSkillsRated)
  }

  return (
    <>
      <AppConfetti show={isAllSkillsRated} />
      <ModalWindow
        modalProps={{
          open: props.isModalOpen,
          onClose: () => props.isModalClose(),
          PaperProps: {
            sx: {
              maxWidth: { xs: '90%', md: '80%', lg: '60%' },
              borderRadius: '20px',
            },
          },
        }}
      >
        <Zoom in={props.isModalOpen} timeout={theme.transitions.duration.standard} mountOnEnter unmountOnExit>
          <AppBox sx={styles.mainContainer}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: { xs: '90%', md: '80%', lg: '60%' }, justifyContent: 'center' }}>
              {skillAssessmentSet?.skills.map((skill: SkillSet, index: number) => (
                <Step key={skill.id} completed={skill.selectedSetLevel !== null}>
                  <StepButton
                    color="inherit"
                    onClick={() => setActiveStep(index)}
                    sx={{
                      '& .MuiStepIcon-root': {
                        color: activeStep === index ? `${Palette.VIOLET_HEAVEN} !important` : '',
                      },
                    }}
                  ></StepButton>
                </Step>
              ))}
            </Stepper>
            <AppBox sx={{ justifyContent: 'center' }}>
              <Typography variant='h1' m={0} sx={{ color: 'text.primary' }}>{skillAssessmentSet?.skills[activeStep].setName}</Typography>
              <Tooltip color='secondary' title={skillAssessmentSet?.skills[activeStep].setHint}>
                <HelpIcon fontSize='medium' />
              </Tooltip>
            </AppBox>
            <AppBox sx={styles.skillsContainer}>
              {skillAssessmentSet?.skills[activeStep]?.levels.map((level: SkillSetLevel, index: number) => (
                <AppPaper
                  sx={{
                    ...styles.descriptionContainer,
                    backgroundColor: level?.setLevel === skillAssessmentSet?.skills[activeStep].selectedSetLevel ? Palette.HAN_BLUE : '',
                  }}
                  key={level?.id}
                  onClick={() => addUserSkillAssessment(skillAssessmentSet?.skills[activeStep].id, level?.setLevel)}
                >
                  <Typography variant='subtitle1' m={0}>{`${index + 1}. ${level?.setLevelDescription}`}</Typography>
                </AppPaper>
              ))}
            </AppBox>
            <AppDivider />
            <AppBox sx={styles.buttonContainer}>
              <AppButton disabled={activeStep === 0} variant="contained" color='primary' onClick={handleBackStep}>Back</AppButton>
              {activeStep === skillAssessmentSet.skills.length - 1 ? (
                <AppButton onClick={handleFinish} variant="contained" color="primary">Finish</AppButton>
              ) : (
                <AppButton variant="contained" color="primary" onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}>Next</AppButton>
              )}
            </AppBox>
          </AppBox>
        </Zoom>
      </ModalWindow>
    </>
  )
})

const styles = {
  mainContainer: {
    marginTop: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  skillsContainer: {
    flexDirection: 'column',
    gap: 10,
    width: '100%',
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  descriptionContainer: {
    borderColor: Palette.HAN_BLUE,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#36bbaf96',
    },
    borderRadius: '15px',
    padding: '0.7em',
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
  buttonContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    gap: 10,
  },
}
