import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { useNavigate } from 'react-router-dom'
import { MetricWithValue } from './MetricWithValue'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useEffect, useState } from 'react'
import { useDashboardStore } from '../store/dashboard.store'
import { Line, LineChart, ResponsiveContainer } from 'recharts'
import { Fade } from '@mui/material'
import { useControlPanelStore } from 'src/feature/control-panel/store/control-panel.store'
import { AddUser } from './AddUser'

export type DepartmentCardsProps = {
  levelId: string
}

export const DepartmentCards = observer(({ levelId }: DepartmentCardsProps) => {
  const navigate = useNavigate()
  const dashboardStore = useDashboardStore()
  const controlPanelStore = useControlPanelStore()
  const [showInviteDialog, setShowInvite] = useState(false)
  const data = [
    { date: '2024-01-01', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-02', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-03', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-04', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-05', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-06', value: Math.floor(Math.random() * 1000) },
    { date: '2024-01-07', value: Math.floor(Math.random() * 1000) },
  ]

  useEffect(() => {
    dashboardStore.getTeamOverview(levelId)
  }, [levelId])

  const closeInvite = (reload: boolean) => {
    if (reload) {
      controlPanelStore.updateOrganisationUsers()
    }

    setShowInvite(false)
  }

  return (
    <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
      <AppPaper key={levelId} sx={styles.root}>
        <Title2 m={0} style={{ fontSize: '1.5rem' }}>{dashboardStore.teamOverview?.[levelId]?.organisationLevelName}</Title2>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='small' label={'Total Users'} value={dashboardStore.teamOverview?.[levelId]?.totalUsers || 0} />
          <MetricWithValue size='small' label={'Active Users'} value={dashboardStore.teamOverview?.[levelId]?.activeUsers || 0} />
          <MetricWithValue size='small' label={'Inactive Users'} value={dashboardStore.teamOverview?.[levelId]?.inactiveUsers || 0} />
          <MetricWithValue size='small' label={'Courses Given'} value={dashboardStore.teamOverview?.[levelId]?.assignedCourses || 0} />
          <MetricWithValue size='small' label={'Users Complete'} value={dashboardStore.teamOverview?.[levelId]?.totalCoursesUsersCompleted || 0} />
          <MetricWithValue size='small'
            color={(dashboardStore.teamOverview?.[levelId]?.completionRate || 0) < 50 ? 'error.main' : 'success.main'}
            label={'Complete Rate'}
            value={`${dashboardStore.teamOverview?.[levelId]?.completionRate || 0}%`}
          />
          <ResponsiveContainer style={{ position: 'absolute' }} width="100%" height="100%">
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={2}
                isAnimationActive={true}
                animationBegin={0}
                animationDuration={1500}
                animationEasing="ease-in-out"
                opacity={0.2}
              />
            </LineChart>
          </ResponsiveContainer>
        </AppBox>
        <AppBox sx={styles.buttonWrapper}>
          <AppButton variant='contained' color="primary" onClick={() => setShowInvite(true)}>{'Add user'}</AppButton>
          <AppButton
            variant='contained'
            color='primary'
            onClick={() => navigate(appRoutePath.administratorUsers(levelId))}
          >{'View More'}</AppButton>
        </AppBox>
        {showInviteDialog && <AddUser levelId={levelId} show={showInviteDialog} onClose={closeInvite} />}
      </AppPaper>
    </Fade>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
    padding: '2.5rem 2rem 0.5rem 2rem',
    borderRadius: '1rem',
    '&:hover': {
      boxShadow: '0 0 1em 0 rgba(0, 0, 0, 0.1)',
    },
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(3, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' },
    columnGap: 10,
    rowGap: 5,
    position: 'relative',
  },
  buttonWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' },
    gap: '1rem',
  },
}
