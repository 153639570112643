import { Box, Collapse, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { AppProgressLine } from 'src/component/common/analog/molecule/loader/AppProgressLine'
import { useSkillAssessmentStore } from 'src/feature/skill-assessment/store/skill-assessment.store'
import { Palette } from 'src/feature/theme/model/Palette'
import { useUserStore } from 'src/feature/user/store/user.store'

export const GetStarted = observer(() => {
  const { userData } = useUserStore()
  const skillAssessmentStore = useSkillAssessmentStore()
  const { isLoading } = skillAssessmentStore.getGenericFieldsState('getSkillSets')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    skillAssessmentStore.getSkillSets()
  }, [])

  useEffect(() => {
    if (!isLoading && !skillAssessmentStore.skillSets?.skills?.length) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [isLoading, skillAssessmentStore.skillSets])

  return (
    <Box sx={styles.root}>
      <Typography style={{ fontSize: '2rem', fontWeight: 400 }}>{`Welcome back, ${userData?.firstName || 'User'}!`}</Typography>
      <Typography variant={'body1'} sx={{ width: '70%', textAlign: 'center' }}>{'We admire your skills, and now it’s time to evolve together with us, your manager, and your self-education'}</Typography>
      <Collapse in={open} timeout={400} mountOnEnter unmountOnExit>
        <Typography sx={{ fontWeight: 600, color: Palette.DANGER }}>
          Your manager hasn't assigned any skills yet. Please reach out for further guidance.
        </Typography>
      </Collapse>
      <AppProgressLine sx={{ width: '5rem' }} isShowing={isLoading} />
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
    padding: { xs: '0 1rem', sm: '0 2rem', lg: '0 3rem' },
  },
}