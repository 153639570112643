import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppPaper } from 'src/component/common/analog/molecule/AppPaper'
import { RecommendedCategoryCourses } from './RecommendedCategoryCourses'
import { Palette } from 'src/feature/theme/model/Palette'
import { useEffect } from 'react'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'
import { ELearningCourseCategory } from 'src/feature/e-learning/model/ELearningCourseCategory'

export const RecommendedCategories = observer(() => {
  const eLearningStore = useELearningStore()

  useEffect(() => {
    if (eLearningStore.categories === undefined) {
      eLearningStore.loadCategoriesIfNeeded()
    }
  })

  return (
    <>
      <AppPaper sx={styles.bannerWrapper}>
        <Typography style={{ fontSize: '2rem' }}>{'Out of courses? Find more courses below'}</Typography>
        <Typography>{'If you don’t nurture your personality and skills, they will fade away year after year.'}</Typography>
      </AppPaper>
      {eLearningStore.categories && eLearningStore.categories.slice(0, 3).map((category: ELearningCourseCategory) => <Box key={category.id} sx={{ padding: { xs: '0 2rem', md: '0 6rem', lg: '0 10rem' } }} >
        <RecommendedCategoryCourses category={category.titleLocale} />
      </Box>)}
    </>
  )
})

const styles = {
  bannerWrapper: {
    padding: '5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 15,
    backgroundColor: Palette.DEEP_SEA_BLUE,
    color: Palette.WHITE,
  },
}
