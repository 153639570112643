import { observer } from 'mobx-react-lite'
import { Box, CircularProgress, circularProgressClasses, Fade, Typography } from '@mui/material'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { Palette } from 'src/feature/theme/model/Palette'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { SkillSet } from '../model/skill-assessment-model'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'
import { useEffect, useState } from 'react'
import { useSkillAssessmentStore } from '../store/skill-assessment.store'
import { reaction } from 'mobx'

export type SkillAssessmentRatingProps = {
  onRateSkillClick: (selectedSkillSetIndex: number) => void
}

export const SkillAssessmentRating = observer((props: SkillAssessmentRatingProps) => {
  const [progressValues, setProgressValues] = useState<number[]>([])
  const skillAssessmentStore = useSkillAssessmentStore()

  useEffect(() => {
    updateProgressValues()
    reaction(
      () => skillAssessmentStore.skillSets?.skills.map(skill => skill.selectedSetLevel),
      updateProgressValues,
    )
  }, [skillAssessmentStore.skillSets?.skills])

  const updateProgressValues = () => {
    if (skillAssessmentStore.skillSets?.skills) {
      const initialProgressValues = skillAssessmentStore.skillSets.skills.map(
        skill => ((skill?.selectedSetLevel || 0) / 5) * 100,
      )
      setProgressValues(initialProgressValues)
    }
  }

  return (
    <AppBox sx={styles.mainContainer}>
      {skillAssessmentStore.skillSets?.skills?.length !== 0 && skillAssessmentStore.skillSets?.skills?.map((skill: SkillSet, index: number) => (
        <Fade in={true} timeout={800} key={skill.id} mountOnEnter unmountOnExit>
          <Box sx={{ position: 'relative', display: 'inline-flex', width: '180px', height: '180px' }} key={skill.id}>
            <CircularProgress variant="determinate" value={100} sx={{
              ...styles.circlePosition,
              position: 'absolute',
              color: Palette.BRIGHT_GREY_2,
            }} size={180} thickness={4} />
            <CircularProgress variant="determinate" sx={{
              color: Palette.HAN_BLUE,
              animationDuration: '600ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }} size={180} thickness={4} value={progressValues[index]} />
            <Box
              sx={{
                ...styles.circlePosition,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Caption
                variant="caption"
                component="div"
                color="text.primary"
                sx={{ fontWeight: '500' }}
              >
                <AppBox sx={styles.circleContainer}>
                  <Typography sx={styles.wordWrapper}>{skill.setName}</Typography>
                  <AppButton sx={styles.buttonContainer} onClick={() => props.onRateSkillClick(index)}>{progressValues[index] === 0 ? 'Rate Skill' : 'Update Skill'}</AppButton>
                </AppBox>
              </Caption>
            </Box>
          </Box>
        </Fade>
      ))}
    </AppBox>
  )
})

const styles = {
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' },
    rowGap: '0.5rem',
    columnGap: '1rem',
    alignItems: 'stretch',
    '& > :nth-of-type(5n+2), & > :nth-of-type(5n+4)': {
      lg: { transform: 'translateY(5rem)' },
    },
  },
  circleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '9em',
    gap: 7,
  },
  circlePosition: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  wordWrapper: {
    textAlign: 'center',
    wordWrap: 'break-word',
    fontWeight: '500',
  },
  buttonContainer: {
    backgroundColor: Palette.BRIGHT_GREY_2,
    color: Palette.BLACK_OUT,
    borderRadius: '20px',
    padding: '5px 10px',
    fontSize: '11px',
    fontWeight: '400',
    borderColor: Palette.BRIGHT_GREY_2,
    transition: 'transform 0.3s ease, background-color 0.3s ease',
  },
}
