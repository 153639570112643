import { observer } from 'mobx-react-lite'
import { ELearningCourseCategory } from '../../model/ELearningCourseCategory'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { useNavigate } from 'react-router-dom'
import { appRoutePath } from 'src/feature/router/util/app-route-path'
import { Avatar, Chip, Fade } from '@mui/material'
import { Palette } from 'src/feature/theme/model/Palette'

export type CategoryItemProp = {
  item: ELearningCourseCategory
  index: number
}

export const CategoryItem = observer(({ item, index }: CategoryItemProp) => {
  const { translation } = useLocalizationStore()
  const navigate = useNavigate()
  const { categories } = translation.pages.eLearning
  type ObjectKey = keyof typeof categories
  const texts = categories[item.titleLocale as ObjectKey]

  return (
    <Fade in={true} timeout={index * 50} mountOnEnter unmountOnExit>
      <Chip
        key={index}
        avatar={<Avatar sx={styles.avatar}>{texts.title().split(' ')
          .map(word => word[0])
          .join('')}</Avatar>}
        sx={{ justifyContent: 'start', height: '1.7rem' }}
        label={texts.title()}
        onClick={() => navigate(appRoutePath.learningCategory(item.titleLocale))}
      />
    </Fade>
  )
})

const styles = {
  avatar: {
    backgroundColor: Palette.DEEP_SEA_BLUE,
    color: `${Palette.WHITE} !important`,
    marginLeft: '0px !important',
    padding: '0.75rem',
  },
}
