import { observer } from 'mobx-react-lite'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Title2 } from 'src/feature/localization/component/atom/Title2'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { Caption } from 'src/feature/localization/component/atom/Caption'
import { useLocalizationStore } from 'src/feature/localization/store/localization.store'
import { Palette } from 'src/feature/theme/model/Palette'
import { CoursePlanItem } from 'src/feature/e-learning/component/organism/CoursePlanItem'
import { AppCarousel } from 'src/component/common/analog/molecule/carousel/AppCarousel'

export type UserCoursesProps = {
  userId: string
}

export const UserCourses = observer(({ userId }: UserCoursesProps) => {
  const dashboardStore = useDashboardStore()
  const { translation } = useLocalizationStore()
  const { isLoading } = dashboardStore.getGenericFieldsState('getUserCourses')
  const texts = translation.pages.eLearning

  useEffect(() => {
    dashboardStore.userCourses = undefined
    dashboardStore.getUserCourses(userId)
  }, [])

  return (
    <Box sx={styles.root}>
      <AppBox sx={styles.courseContainer}>
        <AppBox sx={{ alignItems: 'center' }}>
          <Title2 m={0}>Assigned to {dashboardStore.userOverview?.[userId]?.userName} ({dashboardStore.userCourses?.completedAssignedCourses}/{dashboardStore.userCourses?.assignedCourses?.length} done)</Title2>
        </AppBox>
        {!isLoading && dashboardStore.userCourses && dashboardStore.userCourses?.assignedCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.userCourses?.assignedCourses?.map((c, index: number) => <CoursePlanItem item={c} index={index} key={c.id} managerView={true} />)}
        </AppCarousel>}
        {dashboardStore.userCourses && dashboardStore.userCourses?.assignedCourses?.length === 0 && <Caption textValue={texts.missingCourse} />}
      </AppBox>
      <AppBox sx={styles.courseContainer}>
        <Title2 m={0}>{dashboardStore.userCourses?.completedUserCourses} courses done total</Title2>
        {!isLoading && dashboardStore.userCourses && dashboardStore.userCourses?.completedCourses?.length !== 0 && <AppCarousel showDots={true} showProgressBar={false}>
          {dashboardStore.userCourses?.completedCourses?.map((c, index: number) => <CoursePlanItem item={c} index={index} key={c.id} managerView={true} />)}
        </AppCarousel>}
        {dashboardStore.userCourses && dashboardStore.userCourses?.completedCourses?.length === 0 && <Caption textValue={texts.missingCourse} />}
      </AppBox>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0 4rem',
    gap: '2rem',
  },
  courseContainer: {
    flexDirection: 'column',
    overflow: 'hidden',
    borderRadius: '0.75rem',
    border: `1px solid ${Palette.HAN_BLUE}40`,
    padding: '0.75rem 0.75rem 0.4rem 0.75rem',
    gap: 5,
  },
}
