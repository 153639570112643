import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ExpectedRating } from '../component/ExpectedRating'
import { GoBackButtonWithArrow } from 'src/component/common/original/molecule/GoBackButtonWithArrow'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { UserList } from '../component/UserList'
import { UserMetrics } from '../component/UserMetrics'
import { DepartmentAssignedCourses } from '../component/DepartmentAssignedCourses'
import { useDashboardStore } from '../store/dashboard.store'

export type AdministratorUsersPageProps = {
  levelId: string
}

export const AdministratorUsersPage = observer(({ levelId }: AdministratorUsersPageProps) => {
  const dashboardStore = useDashboardStore()

  return (
    <Box>
      <AppBox sx={styles.backButtonWrapper}>
        <GoBackButtonWithArrow textValue={`My team > ${dashboardStore.teamOverview?.[levelId]?.organisationLevelName}`} />
      </AppBox>
      <Box sx={styles.root}>
        <UserMetrics levelId={levelId}/>
        <UserList levelId={levelId}/>
        <ExpectedRating levelId={levelId}/>
        <DepartmentAssignedCourses levelId={levelId}/>
      </Box>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4rem',
  },
  backButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 3rem',
  },
}
