import { Box, Fade, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { MetricWithValue } from './MetricWithValue'
import { useDashboardStore } from '../store/dashboard.store'
import { useEffect } from 'react'
import { useELearningStore } from 'src/feature/e-learning/store/e-learning.store'
import { Title1 } from 'src/feature/localization/component/atom/Title1'
import { AppButton } from 'src/component/common/analog/molecule/button/AppButton'

export const GrowthCard = observer(() => {
  const dashboardStore = useDashboardStore()
  const eLearningStore = useELearningStore()

  useEffect(() => {
    if (!dashboardStore.teamCourseOverview) {
      dashboardStore.getTeamCourseOverview()
    }
    if (!eLearningStore.personalCourcePlan) {
      eLearningStore.updatePersonalCoursePlan()
    }
  }, [dashboardStore.teamCourseOverview, eLearningStore.personalCourcePlan])

  return (
    <Box sx={styles.root}>
      <Title1>{'MY TEAM'}</Title1>
      <Typography variant={'body1'} sx={{ width: '70%', textAlign: 'center' }}>{'As a team manager you are free to see all our groups progress. Make sure to visit all group and people to follow the progress and assign  new courses to your teams, or even on a personal level with eae'}</Typography>
      <Fade in={true} timeout={500} mountOnEnter unmountOnExit>
        <AppBox sx={styles.metricsWrapper}>
          <MetricWithValue size='large' label={'Total Users'} value={dashboardStore.teamCourseOverview?.totalUsers || 0} />
          <MetricWithValue size='large' label={'Active Users'} value={dashboardStore.teamCourseOverview?.activeUsers || 0} />
          <MetricWithValue size='large' label={'Inactive Users'} value={dashboardStore.teamCourseOverview?.inactiveUsers || 0} />
        </AppBox>
      </Fade>
      <AppButton variant='contained' color="primary">{'Download report'}</AppButton>
    </Box>
  )
})

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
  },
  metricsWrapper: {
    display: 'grid',
    gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)', lg: 'repeat(3, 1fr)' },
    columnGap: '5rem',
  },
}
