import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { AppBox } from 'src/component/common/analog/molecule/AppBox'
import { GoBackButtonWithArrow } from 'src/component/common/original/molecule/GoBackButtonWithArrow'
import { UserSkillGapAnalysis } from '../component/UserSkillGapAnalysis'
import { UserCourses } from '../component/UserCourses'
import { useDashboardStore } from '../store/dashboard.store'

export type AdministratorUserDetailsPageProps = {
  userId: string
}

export const AdministratorUserDetailsPage = observer(({ userId }: AdministratorUserDetailsPageProps) => {
  const dashboardStore = useDashboardStore()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppBox sx={styles.backButtonWrapper}>
        <GoBackButtonWithArrow textValue={`My team > Users > ${dashboardStore.userOverview?.[userId]?.userName}`} />
      </AppBox>
      <AppBox sx={{ flexDirection: 'column', gap: '2rem', padding: '0 3rem' }}>
        <UserSkillGapAnalysis userId={userId} />
        <UserCourses userId={userId} />
      </AppBox>
    </Box>
  )
})

const styles = {
  backButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 3rem',
  },
}
